<template>
  <div :class="{ home: home === true, takeOver: takeover == true,  MovePos: multi_tourns == 'Y' }">
    <div v-if="home === true">
      <b-container class="mobNocontainer">
        <b-row
          class="homeLeader"
          :class="{ homewidthTakover: takeover == true }"
        >
          <b-col>
            <template v-if="web_sponsor !== ''">
              <b-img v-if="data.code != '1001'" class='courseLogo' :src='web_sponsor'></b-img>
            </template>
            <template v-else>       
              <b-img v-if="data.code != '1001'" class='courseLogo' :src='data.event_sponsor_logo'></b-img>
              <b-img v-else class="courseLogo" :src="data.event_sponsor_logo"></b-img>
            </template>
            <h3 class="homeHead">
              {{ data.short_name }}
              <span v-if="data.live_scoring == 'Y'"> - Live Scoring is on</span>
            </h3>
            <div class="homecourseDates">{{ data.course_dates }}</div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-row v-else class="title">
      <b-col class="titleLeftmobileWidth">
        <h3>
          {{ data.full_name }} - {{ title
          }}
        </h3>
      </b-col>
      <b-col
        class="informationMobilewidth"
        v-if="
          tablePick === 'Score' || tablePick === 'Final' || tablePick === 'Live'
        "
      >
        <!-- <div class="sponLogo">
            <b-img
              class="tLogo"
              :src="
                config.VUE_APP_WP_DIGITAL_OCEAN + '2021/03/titleist_white.png'
              "
            ></b-img
            ><span> Indicates Golf Ball Usage</span>
          </div> -->

        <div class="sponLogo">
          <i v-b-toggle.collapse-1 class="fa-solid fa-circle-info"></i>
        </div>
      </b-col>
    </b-row>
    <b-collapse id="collapse-1" class="mt-2 informationCLass">
      <Information
        :data="data"
        :previousData="previousData"
        :home="home"
        :title="title"
        :config="config"
        :team="team"
        :match="match"
        :code="data.code"
        :report="report"
        :live="live"
        :season="data.season"
        :takeover="takeover"
        :bbTeamEvent="data.bball_team"
        :roundsplayed="data.rounds_played"
        :showGender="showGender"
        :Stableford="Stableford"
        :rts="data.rts[0]"
      />
    </b-collapse>

    <!-- <div v-if="this.compare.length">
      <span class="favtextHead">My Leaderboard</span>
    </div> -->
    <!-- <div v-if="this.compare.length">
      <Favplayer
        :data="data"
        :previousData="previousData"
        :home="home"
        :title="title"
        :config="config"
        :team="team"
        :match="match"
        :code="data.code"
        :report="report"
        :live="live"
        :season="data.season"
        :takeover="takeover"
        :bbTeamEvent="data.bball_team"
        :roundsplayed="data.rounds_played"
        :showGender="showGender"
        :Stableford="Stableford"
        :compare="compare"
      />
    </div> -->
    <table>
      <thead>
        <tr v-if="home === false">
          <template
            v-if="
              (tablePick === 'Score' && data.match_play === 'N') ||
                (tablePick === 'Live' && data.match_play === 'N')
            "
          >
            <th>
              Fav
            </th>
            <th>
              Pos
            </th>
            <template
              v-if="data.live_oom === 'PT' && data.live_points_code !== ''"
            >
              <th class="PadLeft hideOnMobile">
                OOM
              </th>
            </template>
            <th class="playersName-score">
              Player Name
            </th>
            <th class="noPadLeft" v-if="data.multi_course == 'Y'">
              Crs
            </th>
            <th class="noPadLeft hideOnMobile">
              Nat.
            </th>
            <template v-if="data.bball_team == 'Y'">
              <th class="PadLeft">
                Par
              </th>
            </template>
            <template v-else>
              <th
                class="PadLeft"
                v-if="
                  data.score_type === 'P' ||
                    (data.tt_type === 'P' && data.stableford === 'N')
                "
              >
                Par
              </th>
            </template>
            <th
              class="PadLeft"
              v-if="
                data.score_type === 'P' ||
                  (data.tt_type === 'P' && data.stableford === 'Y')
              "
            >
              Points
            </th>
            <template v-if="this.title.includes('Scoreboard')">
              <th
                v-for="(i, roundIt) in range(1, roundsPlayed)"
                :key="roundIt"
                class="hideOnMob"
              >
                <span v-if="roundsPlayed === 1 && tablePick === 'Score'">
                  Score
                </span>
                <span v-else>
                  <span
                    v-if="data.playoff == 'Y' && data.playoff_rounds['playoff_round_' + data.last_round_with_scores] == 'Y' &&
                      i == data.last_round_with_scores
                    "
                    >Playoff</span
                  >
                  <span v-else>R{{ i }}</span>
                </span>
              </th>
              <template v-if="data.scores.scores_entry[0].score_found === 'Y'">
                <th v-if="roundsPlayed > 1">
                  Score
                </th>
              </template>
              <template v-if="roundsPlayed > 1 && tablePick === 'Score'">
                <th
                  class=""
                  v-if="
                    config.VUE_APP_PLAYER_ICON === 'Y' &&
                      data.scores.scores_entry[0].score_found === 'Y' &&
                      data.last_round_complete === 'Y'
                  "
                ></th>
              </template>
            </template>
            <template
              v-if="
                data.scores.scores_entry[0].score_found === 'N' ||
                  data.scores.scores_entry.score_found === 'N' ||
                  data.round_head == 'Latest Scores'
              "
            >
              <th>
                Thru
              </th>
            </template>
            <template
              v-else-if="
                (data.scores.scores_entry[0].score_found === 'Y' &&
                  this.title.includes('Scores Only')) ||
                  this.title.includes('Latest Scores')
              "
            >
              <th>Score</th>
            </template>
            <template v-if="data.bball_team != 'Y'">
              <th></th>
            </template>
          </template>
        </tr>
      </thead>
      <!-- compare update -->
      <tbody v-if="this.compare.length">
        <template v-for="(tabledata, index) in compare">
          <tr
            class="compareBk"
            @click.stop="rowClickedCompare(index)"
            :key="index"
            v-bind:class="{
              'homeTd-size': home === true,
              drawHome: home === true && tablePick === 'Draw',
            }"
          >
            <template
              v-if="
                (tablePick === 'Score' && data.match_play === 'N') ||
                  (tablePick === 'Live' && data.match_play === 'N')
              "
            >
              <td >
                <img class="favStar" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2024/02/star_selected_White.png">
              </td>
              <td class="cut" colspan="11" v-if="tabledata.pos === 'CUT'">
                {{ tabledata.cut_round }}
              </td>
              <td
                class="posnum"
                v-else-if="
                  index !== 0
                "
              >
                <span>
                  {{ tabledata.tied}} {{ tabledata.pos }}
                </span>
              </td>
              <td class="posnum" v-else-if="index === 0">
                <span>
                  {{ tabledata.tied}} {{ tabledata.pos }}
                </span>
              </td>
              <td v-else class="noPos"><span>.</span></td>
              <td v-if="data.live_oom == 'PT' && data.live_points_code !== '' && tabledata.pos !== 'CUT'" class="hideOnMobile">
                <span
                  :class="{Arrowup:tabledata.oom_diff_pos > 0, Arrowdown:tabledata.oom_diff_pos < 0}"
                  class="scores-pos"
                  v-if="tabledata.oom_pos.length > 0 && tabledata.oom_pos !=='0'"
                >
                  {{ tabledata.oom_pos }}
                  <font-awesome-icon v-if="tabledata.oom_diff_pos > 0" class="icon" :icon="['fa', 'arrow-up']" />
                  <font-awesome-icon v-if="tabledata.oom_diff_pos < 0" class="icon" :icon="['fa', 'arrow-down']" />
                </span>
              </td>
              <td
                class="PlayerName"
                v-if="
                  tabledata.playerno !== 'Cat' &&
                    tabledata.pos !== 'CUT' &&
                    team === 'N'
                "
                @click="playerCard"
                :class="{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down',
                }"
              >
                <a
                  :href="'/player-profiles/' + tabledata.member_no"
                  :class="{ homePlayername: home === true }"
                >
                  {{ tabledata.name }} <span v-if="tabledata.pro_ind == 'Am'">(a)</span>
                </a>
                <span v-if="tabledata.sponsored === 'Y' && home !== true">
                  <img
                    class="titliestLogo"
                    src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2021/03/titleist_oom-copy.png"
                  />
                </span>
                <span
                  v-if="showGender === 'Y'"
                  class="multiDot-size-reportgender"
                  :class="{female : tabledata.gender === 'F', male : tabledata.gender === 'M'}"
                  >•</span>
              </td>
              <td
                class="PlayerName"
                v-if="
                  tabledata.playerno !== 'Cat' &&
                    tabledata.pos !== 'CUT' &&
                    team === 'Y'
                "
                @click="playerCard"
                :class="{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down',
                }"
              >
                {{ tabledata.name }} <span v-if="tabledata.pro_ind == 'Am'">(a)</span>
                <span v-if="tabledata.sponsored === 'Y' && home !== true">
                  <img
                    class="titliestLogo"
                    src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2021/03/titleist_oom-copy.png"
                  />
                </span>
                <span
                  v-if="showGender === 'Y'"
                  class="multiDot-size-reportgender"
                  :class="{female : tabledata.gender === 'F', male : tabledata.gender === 'M'}"
                  >•</span>
              </td>
              <td v-if="data.multi_course === 'Y' && tabledata.pos !== 'CUT'">
                <span
                  v-if="data.multi_course === 'Y'"
                  :class="tabledata['course_colour_R' + data.pft_round]"
                  class="multiDot-size-report"
                  >•</span
                >
              </td>
              <td
                v-bind:class="{ natNone: home === true }"
                class="mobHide"
                v-if="tabledata.pos !== 'CUT'"
              >
                <span v-if="tabledata.nationality === ''"></span>
                <span v-else>
                  <img
                    class="flag"
                    :src="
                      (config.VUE_APP_FLAG_URL + tabledata.nationality)
                        | lowercase
                    "
                  />
                </span>
              </td>
              <template
                v-if="
                  data.score_type === 'P' ||
                    (data.tt_type === 'P' && data.stableford === 'N')
                "
              >
                <td v-if="tabledata.vspar < 0" class="up">
                  {{ tabledata.vspar }}
                </td>
                <td v-else-if="tabledata.vspar > 0" class="down">
                  {{ tabledata.vspar }}
                </td>
                <td v-else-if="tabledata.pos !== 'CUT'" :class="{'nonScore': tabledata.vspar == 'RTD'}">
                  {{ tabledata.vspar }}
                </td>
              </template>
              <template
                v-if="
                  data.score_type === 'P' ||
                    (data.tt_type === 'P' && data.stableford === 'Y')
                "
              >
                <td v-if="tabledata.pos !== 'CUT'">
                  {{ tabledata.score }}
                </td>
              </template>
              <template
                v-if="
                  title.includes('Scoreboard') &&
                    tabledata.pos !== 'CUT' &&
                    data.stableford === 'Y'
                "
              >
                <td
                  v-for="(i, roundIt) in range(1, roundsPlayed)"
                  :key="roundIt"
                  class="mobnone hideOnMob"
                >
                  <!-- <span
                    v-if="roundsPlayed === 1 && tabledata.vspar < 0"
                    class="up"
                    >{{ tabledata.score }} </span
                  >
                  <span
                    v-else-if="roundsPlayed === 1 && tabledata.vspar > 0"
                    class="down"
                    >{{ tabledata.score }}</span
                  >
                  <span v-else-if="roundsPlayed === 1">{{
                    tabledata.score
                  }}</span> -->
                  <span v-if="tabledata['score_R' + i] > 0" class="plus">{{
                    tabledata["score_R" + i]
                  }}</span>
                  <span v-else-if="tabledata['score_R' + i] < 0" class="minus">
                    {{ tabledata["score_R" + i] }}
                  </span>
                  <span v-else class="zero">
                    {{ tabledata["score_R" + i] }}
                  </span>
                </td>
                <template v-if="tabledata.score_found === 'Y'">
                  <td v-if="tabledata.vspar < 0 && roundsPlayed > 1" class="">
                    {{ tabledata.score }}
                  </td>
                  <td
                    v-else-if="tabledata.vspar > 0 && roundsPlayed > 1"
                    class=""
                  >
                    {{ tabledata.score }}
                  </td>
                  <td v-else-if="roundsPlayed > 1">
                    {{ tabledata.score }}
                  </td>
                </template>
              </template>
              <template
                v-if="
                  title.includes('Scoreboard') &&
                    tabledata.pos !== 'CUT' &&
                    data.stableford === 'N'
                "
              >
                <td
                  v-for="(i, roundIt) in range(1, roundsPlayed)"
                  :key="roundIt"
                  class="mobnone hideOnMob"
                >
                  <span
                    v-if="roundsPlayed === 1 && tabledata.vspar < 0"
                    class="up" 
                    >{{ tabledata.score }}</span
                  >
                  <span
                    v-else-if="roundsPlayed === 1 && tabledata.vspar > 0"
                    class="down"
                    >{{ tabledata.score }}</span
                  >
                  <span v-else-if="roundsPlayed === 1" >{{
                    tabledata.score
                  }}</span>
                  <span v-else-if="tabledata['vspar_R' + i] < 0" :class="up" >{{
                    tabledata["score_R" + i]
                  }}</span>
                  <span
                    v-else-if="tabledata['vspar_R' + i] > 0"
                    :class="down"
                    >{{ tabledata["score_R" + i] }}</span
                  >
                  <span v-else :class="{'nonScore': tabledata.vspar == 'RTD'}">{{ tabledata["score_R" + i] }}</span>
                </td>
                <template v-if="tabledata.score_found === 'Y'">
                  <td v-if="tabledata.vspar < 0 && roundsPlayed > 1" class="up">
                    {{ tabledata.score }}
                  </td>
                  <td
                    v-else-if="tabledata.vspar > 0 && roundsPlayed > 1"
                    class="down"
                  >
                    {{ tabledata.score }}
                  </td>
                  <td v-else-if="roundsPlayed > 1">
                    {{ tabledata.score }}
                  </td>
                </template>
              </template>
              <template v-else-if="data.round_head == 'Latest Scores' || tabledata.score_found == 'Y'">
                <td
                  v-for="(i, roundIt) in range(1, roundsPlayed)"
                  :key="roundIt"
                  class="mobnone hideOnMob"
                >
                  <span
                    v-if="roundsPlayed === 1 && tabledata.vspar < 0"
                    class="up" 
                    >{{ tabledata.score }}</span
                  >
                  <span
                    v-else-if="roundsPlayed === 1 && tabledata.vspar > 0"
                    class="down"
                    >{{ tabledata.score }}</span
                  >
                  <span v-else-if="roundsPlayed === 1" >{{
                    tabledata.score
                  }}</span>
                  <span v-else-if="tabledata['vspar_R' + i] < 0" :class="up" >{{
                    tabledata["score_R" + i]
                  }}</span>
                  <span
                    v-else-if="tabledata['vspar_R' + i] > 0"
                    :class="down"
                    >{{ tabledata["score_R" + i] }}</span
                  >
                  <span v-else :class="{'nonScore': tabledata.vspar == 'RTD'}">{{ tabledata["score_R" + i] }}</span>
                </td>
                <template v-if="tabledata.score_found === 'Y'">
                  <td v-if="tabledata.vspar < 0 && roundsPlayed > 1" class="up">
                    {{ tabledata.score }}
                  </td>
                  <td
                    v-else-if="tabledata.vspar > 0 && roundsPlayed > 1"
                    class="down"
                  >
                    {{ tabledata.score }}
                  </td>
                  <td v-else-if="roundsPlayed > 1">
                    {{ tabledata.score }}
                  </td>
                </template>
              </template>
              <template
                v-if="
                  data.round_head == 'Latest Scores' ||
                    tabledata.score_found == 'N'
                "
              >
                <td style="color: #000;" v-if="tabledata.pos !== 'CUT'">
                  <template
                    v-if="tabledata.holes && tabledata.holes.length > 0"
                  >
                    <span class="mobLevel" v-show="tabledata.holes != '18'"
                      >{{ tabledata.holes }} 
                      <span v-if="tabledata['tee_R' + data.pft_round] > 1"
                        >*</span
                      >
                        <span v-if="data.rts[1] == 'S'" class="strokesColour">{{tabledata['strokes_R' + data.pft_round].split(",")[1]}}</span>
                      </span
                    >
                    <span v-show="tabledata.holes == '18'">F</span>
                  </template>
                  <template v-else>
                    {{ tabledata["time_R" + data.pft_round] }}
                  </template>
                </td>
              </template>
              <td
                v-if="
                  tabledata.playerno !== 'Cat' &&
                    tabledata.playerno !== 'EOF' &&
                    tabledata.pos !== 'CUT'
                "
              >
                <a :href="'/player-profiles/' + tabledata.member_no" v-if="tabledata.profile == 'Y'" target="_blank">
                  <span class="iconRight">
                    <font-awesome-icon
                      class="icon"
                      :icon="['fa', 'chevron-right']"
                    />
                  </span>
                </a>
              </td>
            </template>
            <template
              v-if="
                (tablePick === 'Score' && data.match_play === 'Y') ||
                  (tablePick === 'Final' && data.match_play === 'Y')
              "
            >
              <td
                :class="{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down',
                  win: tabledata.status_1 == 'W',
                }"
              >
                {{ tabledata.initials_1 }} {{ tabledata.surname_1 }}
              </td>
              <td v-if="tabledata.status_1 === 'W'" class="win">
                {{ tabledata.full_score_1 }}
              </td>
              <td v-else></td>
              <td>
                <span v-if="tabledata.holes === ''">
                  {{ tabledata.time }}
                </span>
                <span class="mobLevel" v-else-if="tabledata.holes < 18">
                  {{ tabledata.holes
                  }}<span v-if="tabledata.tee_R1 > 1">*</span>
                </span>
                <span v-else>
                  F<span v-if="tabledata.tee_R1 > 1">*</span>
                </span>
              </td>
              <td v-if="tabledata.status_2 === 'W'" class="win">
                {{ tabledata.full_score_2 }}
              </td>
              <td v-else></td>
              <td
                :class="{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down',
                  win: tabledata.status_2 == 'W',
                }"
              >
                {{ tabledata.initials_2 }} {{ tabledata.surname_2 }}
              </td>
            </template>
          </tr>
        </template>
      </tbody>
      <!-- end of compare section -->
      <tbody>
        <template v-for="(tabledata, index) in Eds">
          <template v-if="home == false">
            <!-- <tr :key="index" v-if="index == '4'">
              <td colspan="11" class="Desk">
                <script type="application/javascript">
                  window.googletag = window.googletag || { cmd: [] };
                  googletag.cmd.push(function() {
                    googletag
                      .defineSlot(
                        "/22264165340/LET_RTCDS_Leaderboard_2021_728x90",
                        [728, 90],
                        "div-gpt-ad-1615799143186-0"
                      )
                      .addService(googletag.pubads());
                    googletag.pubads().enableSingleRequest();
                    googletag.enableServices();
                    googletag.pubads().collapseEmptyDivs();
                  });
                </script>
                <div
                  id="div-gpt-ad-1615799143186-0"
                  style="width: 728px; height: 90px; margin: auto;"
                >
                  <script type="application/javascript">
                    googletag.cmd.push(function() {
                      googletag.display("div-gpt-ad-1615799143186-0");
                    });
                  </script>
                </div>
              </td>
              <td colspan="11" class="Mob">
                <script type="application/javascript">
                  window.googletag = window.googletag || { cmd: [] };
                  googletag.cmd.push(function() {
                    googletag
                      .defineSlot(
                        "/22264165340/LET_RTCDS_Leaderboard_2021_728x90",
                        [320, 50],
                        "div-gpt-ad-1615799143186-0"
                      )
                      .addService(googletag.pubads());
                    googletag.pubads().enableSingleRequest();
                    googletag.enableServices();
                    googletag.pubads().collapseEmptyDivs();
                  });
                </script>
                <div
                  id="div-gpt-ad-1615799143186-0"
                  style="width: 320px; height: 50px; margin: auto;"
                >
                  <script type="application/javascript">
                    googletag.cmd.push(function() {
                      googletag.display("div-gpt-ad-1615799143186-0");
                    });
                  </script>
                </div>
              </td>
            </tr> -->
          </template>
          <tr
            @click.stop="rowClicked(index)"
            :key="index"
            v-bind:class="{
              'homeTd-size': home === true,
              'homeTd-sizehover': home === true,
              takeTR: takeover == true,
              posMoveHeight: multi_tourns == 'Y',
              drawHome: home === true && tablePick === 'Draw'
            }"
          >
            <template
              v-if="
                (tablePick === 'Score' && data.match_play === 'N') ||
                  (tablePick === 'Live' && data.match_play === 'N')
              "
            >
              <td
                v-if="tabledata.pos !== 'CUT' && home !== true"
                class="wrapper"
              >
                <input
                  type="checkbox"
                  :id="tabledata.member_no"
                  v-model="compare"
                  :value="tabledata"
                  :name="tabledata.member_no"
                />
                <label :for="tabledata.member_no"></label>
              </td>
              <td class="cut" colspan="11" v-if="tabledata.pos === 'CUT'">
                {{ tabledata.cut_round }}
              </td>
              <td
                class="posnum"
                v-else-if="index !== 0 && tabledata.pos !== 'CUT'"
              >
                <template v-if="tabledata.pos.length > 0">
                  <span> {{ tabledata.tied }} {{ tabledata.pos }} </span>
                </template>
              </td>
              <td
                class="posnum"
                v-else-if="index === 0 && tabledata.pos !== 'CUT'"
              >
                <template v-if="tabledata.pos.length > 0">
                  <span> {{ tabledata.tied }} {{ tabledata.pos }} </span>
                </template>
              </td>
              <td v-else class="noPos"><span>.</span></td>
              <td
                v-if="
                  home == false &&
                    data.live_oom == 'PT' &&
                    data.live_points_code !== '' &&
                    tabledata.pos !== 'CUT'
                "
                class="hideOnMobile"
              >
                <span
                  :class="{
                    Arrowup: tabledata.oom_diff_pos > 0,
                    Arrowdown: tabledata.oom_diff_pos < 0
                  }"
                  class="scores-pos"
                  v-if="
                    tabledata.oom_pos.length > 0 && tabledata.oom_pos !== '0'
                  "
                >
                  {{ tabledata.oom_pos }}
                  <font-awesome-icon
                    v-if="tabledata.oom_diff_pos > 0"
                    class="icon"
                    :icon="['fa', 'arrow-up']"
                  />
                  <font-awesome-icon
                    v-if="tabledata.oom_diff_pos < 0"
                    class="icon"
                    :icon="['fa', 'arrow-down']"
                  />
                </span>
              </td>
              <template v-if="home == true || isMobile(true)">
                <td
                  class="Playerhome"
                  v-if="
                    tabledata.playerno !== 'Cat' &&
                      tabledata.pos !== 'CUT' &&
                      team === 'N'
                  "
                  :class="{
                    'text-up': tabledata.posChang == 'up',
                    'text-down': tabledata.posChang == 'down'
                  }"
                >
                  <a
                    :href="'/player-profiles/' + tabledata.member_no"
                    class="homeLinkPlayer"
                  >
                    {{ tabledata.name }}
                    <span v-if="tabledata.pro_ind == 'Am'">(a)</span>
                  </a>
                  <span v-if="tabledata.sponsored === 'Y' && home !== true">
                    <img
                      class="titliestLogo"
                      src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2021/03/titleist_oom-copy.png"
                    />
                  </span>
                  <span
                    v-if="showGender === 'Y'"
                    class="multiDot-size-reportgender"
                    :class="{
                      female: tabledata.gender === 'F',
                      male: tabledata.gender === 'M'
                    }"
                    >•</span
                  >
                </td>
                <td
                  class="PlayerName Playerhome"
                  v-if="
                    tabledata.playerno !== 'Cat' &&
                      tabledata.pos !== 'CUT' &&
                      team === 'Y'
                  "
                  :class="{
                    'text-up': tabledata.posChang == 'up',
                    'text-down': tabledata.posChang == 'down'
                  }"
                >
                  {{ tabledata.name }}
                  <span v-if="tabledata.pro_ind == 'Am'">(a)</span>
                  <span v-if="tabledata.sponsored === 'Y' && home !== true">
                    <img
                      class="titliestLogo"
                      src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2021/03/titleist_oom-copy.png"
                    />
                  </span>
                  <span
                    v-if="showGender === 'Y'"
                    class="multiDot-size-reportgender"
                    :class="{
                      female: tabledata.gender === 'F',
                      male: tabledata.gender === 'M'
                    }"
                    >•</span
                  >
                </td>
              </template>
              <template v-else>
                <td
                  class="PlayerName"
                  v-if="
                    tabledata.playerno !== 'Cat' &&
                      tabledata.pos !== 'CUT' &&
                      team === 'N'
                  "
                  @click="playerCard(tabledata.id, (toggle = !toggle))"
                  :class="{
                    'text-up': tabledata.posChang == 'up',
                    'text-down': tabledata.posChang == 'down'
                  }"
                >
                  <!-- <a
                    :href="'/player-profiles/' + tabledata.member_no"
                    :class="{ homePlayername: home === true }"
                  > -->
                  {{ tabledata.name }}
                  <span v-if="tabledata.pro_ind == 'Am'">(a)</span>
                  <!-- </a> -->
                  <span v-if="tabledata.sponsored === 'Y' && home !== true">
                    <img
                      class="titliestLogo"
                      src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2021/03/titleist_oom-copy.png"
                    />
                  </span>
                  <span
                    v-if="showGender === 'Y'"
                    class="multiDot-size-reportgender"
                    :class="{
                      female: tabledata.gender === 'F',
                      male: tabledata.gender === 'M'
                    }"
                    >•</span
                  >
                </td>
                <td
                  class="PlayerName"
                  v-if="
                    tabledata.playerno !== 'Cat' &&
                      tabledata.pos !== 'CUT' &&
                      team === 'Y'
                  "
                  @click="playerCard(tabledata.id, (toggle = !toggle))"
                  :class="{
                    'text-up': tabledata.posChang == 'up',
                    'text-down': tabledata.posChang == 'down'
                  }"
                >
                  {{ tabledata.name }}
                  <span v-if="tabledata.pro_ind == 'Am'">(a)</span>
                  <span v-if="tabledata.sponsored === 'Y' && home !== true">
                    <img
                      class="titliestLogo"
                      src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2021/03/titleist_oom-copy.png"
                    />
                  </span>
                  <span
                    v-if="showGender === 'Y'"
                    class="multiDot-size-reportgender"
                    :class="{
                      female: tabledata.gender === 'F',
                      male: tabledata.gender === 'M'
                    }"
                    >•</span
                  >
                </td>
              </template>
              <td v-if="data.multi_course === 'Y' && tabledata.pos !== 'CUT'">
                <span
                  v-if="data.multi_course === 'Y'"
                  :class="tabledata['course_colour_R' + data.pft_round]"
                  class="multiDot-size-report"
                  >•</span
                >
              </td>
              <td
                v-bind:class="{ natNone: home === true }"
                class="mobHide hideOnMobile"
                v-if="tabledata.pos !== 'CUT'"
              >
                <span v-if="tabledata.nationality === ''"></span>
                <span v-else>
                  <img
                    class="flag"
                    :src="
                      (config.VUE_APP_FLAG_URL + tabledata.nationality)
                        | lowercase
                    "
                  />
                </span>
              </td>
              <template
                v-if="
                  (data.bball_team == 'N' && data.score_type === 'P') ||
                    (data.tt_type === 'P' && data.stableford === 'N')
                "
              >
                <td v-if="tabledata.vspar < 0" class="up">
                  {{ tabledata.vspar }}
                </td>
                <td v-else-if="tabledata.vspar > 0" class="down">
                  {{ tabledata.vspar }}
                </td>
                <td
                  v-else-if="tabledata.pos !== 'CUT'"
                  :class="{
                    nonScore: tabledata.vspar == 'RTD',
                    smallsize: tabledata.score == 'WDN'
                  }"
                >
                  {{ tabledata.vspar }}
                </td>
              </template>
              <template v-else>
                <td v-if="tabledata.vspar < 0" class="up">
                  {{ tabledata.vspar }}
                </td>
                <td v-else-if="tabledata.vspar > 0" class="down">
                  {{ tabledata.vspar }}
                </td>
                <td
                  v-else-if="tabledata.pos !== 'CUT'"
                  :class="{
                    nonScore: tabledata.vspar == 'RTD',
                    smallsize: tabledata.score == 'WDN'
                  }"
                >
                  {{ tabledata.vspar }}
                </td>
              </template>
              <template
                v-if="
                  data.score_type === 'P' ||
                    (data.tt_type === 'P' && data.stableford === 'Y')
                "
              >
                <td v-if="tabledata.pos !== 'CUT'">
                  {{ tabledata.score }}
                </td>
              </template>
              <template
                v-if="
                  title.includes('Scoreboard') &&
                    tabledata.pos !== 'CUT' &&
                    data.stableford === 'Y'
                "
              >
                <td
                  v-for="(i, roundIt) in range(1, roundsPlayed)"
                  :key="roundIt"
                  class="mobnone hideOnMob"
                >
                  <span v-if="tabledata['score_R' + i] > 0" class="plus">{{
                    tabledata["score_R" + i]
                  }}</span>
                  <span v-else-if="tabledata['score_R' + i] < 0" class="minus">
                    {{ tabledata["score_R" + i] }}
                  </span>
                  <span v-else class="zero">
                    {{ tabledata["score_R" + i] }}
                  </span>
                </td>
                <template v-if="tabledata.score_found === 'Y'">
                  <td v-if="tabledata.vspar < 0 && roundsPlayed > 1" class="">
                    {{ tabledata.score }}
                  </td>
                  <td
                    v-else-if="tabledata.vspar > 0 && roundsPlayed > 1"
                    class=""
                  >
                    {{ tabledata.score }}
                  </td>
                  <td v-else-if="roundsPlayed > 1">
                    {{ tabledata.score }}
                  </td>
                </template>
              </template>
              <template
                v-if="
                  title.includes('Scoreboard') &&
                    tabledata.pos !== 'CUT' &&
                    data.stableford === 'N'
                "
              >
                <template v-if="data.full_name.includes('- TEAM')">
                  <td
                    v-for="(i, roundIt) in range(1, roundsPlayed)"
                    :key="roundIt"
                    class="mobnone hideOnMob"
                  >
                    <span
                      v-if="roundsPlayed === 1 && tabledata.vspar < 0"
                      class="up"
                      >{{ tabledata.score }}</span
                    >
                    <span
                      v-else-if="roundsPlayed === 1 && tabledata.vspar > 0"
                      class="down"
                      >{{ tabledata.score }}</span
                    >
                    <span v-else-if="roundsPlayed === 1">{{
                      tabledata.score
                    }}</span>
                    <span v-else-if="tabledata['team_vspar_R' + i] < 0" :class="up">{{
                      tabledata["score_R" + i]
                    }}</span>
                    <span
                      v-else-if="tabledata['team_vspar_R' + i] > 0"
                      :class="down"
                      >{{ tabledata["score_R" + i] }}</span
                    >
                    <span
                      v-else
                      :class="{
                        nonScore: tabledata.vspar == 'RTD',
                        smallsize: tabledata['score_R' + i] == 'WDN'
                      }"
                      >{{ tabledata["score_R" + i] }}</span
                    >
                  </td>
                  <template v-if="tabledata.score_found === 'Y'">
                    <td v-if="tabledata.vspar < 0 && roundsPlayed > 1" class="up">
                      {{ tabledata.score }}
                    </td>
                    <td
                      v-else-if="tabledata.vspar > 0 && roundsPlayed > 1"
                      class="down"
                    >
                      {{ tabledata.score }}
                    </td>
                    <td
                      v-else-if="roundsPlayed > 1"
                      :class="{ smallsize: tabledata.score == 'WDN' }"
                    >
                      {{ tabledata.score }}
                    </td>
                  </template>
                </template>
                <template v-else>
                  <td
                    v-for="(i, roundIt) in range(1, roundsPlayed)"
                    :key="roundIt"
                    class="mobnone hideOnMob"
                  >
                    <span
                      v-if="roundsPlayed === 1 && tabledata.vspar < 0"
                      class="up"
                      >{{ tabledata.score }}</span
                    >
                    <span
                      v-else-if="roundsPlayed === 1 && tabledata.vspar > 0"
                      class="down"
                      >{{ tabledata.score }}</span
                    >
                    <span v-else-if="roundsPlayed === 1">{{
                      tabledata.score
                    }}</span>
                    <span v-else-if="tabledata['vspar_R' + i] < 0" :class="up">{{
                      tabledata["score_R" + i]
                    }}</span>
                    <span
                      v-else-if="tabledata['vspar_R' + i] > 0"
                      :class="down"
                      >{{ tabledata["score_R" + i] }}</span
                    >
                    <span
                      v-else
                      :class="{
                        nonScore: tabledata.vspar == 'RTD',
                        smallsize: tabledata['score_R' + i] == 'WDN'
                      }"
                      >{{ tabledata["score_R" + i] }}</span
                    >
                  </td>
                  <template v-if="tabledata.score_found === 'Y'">
                    <td v-if="tabledata.vspar < 0 && roundsPlayed > 1" class="up">
                      {{ tabledata.score }}
                    </td>
                    <td
                      v-else-if="tabledata.vspar > 0 && roundsPlayed > 1"
                      class="down"
                    >
                      {{ tabledata.score }}
                    </td>
                    <td
                      v-else-if="roundsPlayed > 1"
                      :class="{ smallsize: tabledata.score == 'WDN' }"
                    >
                      {{ tabledata.score }}
                    </td>
                  </template>
                </template>
              </template>
              <template
                v-else-if="
                  data.round_head == 'Latest Scores' ||
                    tabledata.score_found == 'Y'
                "
              >
                <td
                  v-for="(i, roundIt) in range(1, roundsPlayed)"
                  :key="roundIt"
                  class="mobnone "
                  v-show="tabledata.pos !== 'CUT'"
                >
                  <span
                    v-if="roundsPlayed === 1 && tabledata.vspar < 0"
                    class="up"
                    >{{ tabledata.score }}</span
                  >
                  <span
                    v-else-if="roundsPlayed === 1 && tabledata.vspar > 0"
                    class="down"
                    >{{ tabledata.score }}</span
                  >
                  <span v-else-if="roundsPlayed === 1">{{
                    tabledata.score
                  }}</span>
                  <span v-else-if="tabledata['vspar_R' + i] < 0" :class="up">{{
                    tabledata["score_R" + i]
                  }}</span>
                  <span
                    v-else-if="tabledata['vspar_R' + i] > 0"
                    :class="down"
                    >{{ tabledata["score_R" + i] }}</span
                  >
                  <span
                    v-else
                    :class="{ nonScore: tabledata.vspar == 'RTD' }"
                    >{{ tabledata["score_R" + i] }}</span
                  >
                </td>
                <template v-if="tabledata.score_found === 'Y'">
                  <td v-if="tabledata.vspar < 0 && roundsPlayed > 1" class="up">
                    {{ tabledata.score }}
                  </td>
                  <td
                    v-else-if="tabledata.vspar > 0 && roundsPlayed > 1"
                    class="down"
                  >
                    {{ tabledata.score }}
                  </td>
                  <td v-else-if="roundsPlayed > 1">
                    {{ tabledata.score }}
                  </td>
                </template>
              </template>
              <template
                v-if="
                  data.round_head == 'Latest Scores' ||
                    tabledata.score_found == 'N'
                "
              >
                <td
                  style="color: #000;"
                  v-if="home === false && tabledata.pos !== 'CUT'"
                >
                  <template
                    v-if="tabledata.holes && tabledata.holes.length > 0"
                  >
                    <span class="mobLevel" v-show="tabledata.holes != '18'"
                      >{{ tabledata.holes }} 
                      <span v-if="tabledata['tee_R' + data.pft_round] > 1"
                        >*</span
                      >
                        <span v-if="data.rts[1] == 'S'" class="strokesColour">{{tabledata['strokes_R' + data.pft_round].split(",")[1]}}</span>
                      </span
                    >
                    <span v-show="tabledata.holes == '18'">F</span>
                  </template>
                  <template v-else>
                    {{ tabledata["time_R" + data.pft_round] }}
                  </template>
                </td>
              </template>
              <template v-if="data.bball_team != 'Y'">
                <td
                  v-if="
                    tabledata.playerno !== 'Cat' &&
                      tabledata.playerno !== 'EOF' &&
                      tabledata.pos !== 'CUT'
                  "
                >
                  <a
                    :href="'/player-profiles/' + tabledata.member_no"
                    v-if="tabledata.profile == 'Y'"
                    target="_blank"
                  >
                    <span class="iconRight">
                      <font-awesome-icon
                        class="icon"
                        :icon="['fa', 'chevron-right']"
                      />
                    </span>
                  </a>
                </td>
              </template>
            </template>
          </tr>
          <tr
            v-if="index === clickedRow && toggle == true"
            class="tr-kids"
            :key="index + Math.random()"
          >
            <td id="reportCard" colspan="11">
              <PlayerReportCard
                :playerData="playerData"
                :playerDataTeam="playerDataTeam"
                :data="data"
                :config="config"
                :actualRound="data.pft_round"
                :roundsPlayed="data.pft_round"
                :scoreType="data.scores_type"
                :team="team"
                :courses="courses"
                :stablford="data.stableford"
                :rts="data.rts[0]"
              />
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div v-if="home === true">
      <b-container class="mobNocontainer">
        <b-row class="FUllLeaderboard homeLeaderHomeNon">
          <b-col class="DrawCol">
            <b-nav-item
              class="fullNav"
              :to="{
                name: 'reports-page',
                query: {
                  url:
                    'https://info.ladieseuropeantour.com/tic/tmdraw.cgi?tourn=' +
                    code +
                    '~season=' +
                    season +
                    '~round=' +
                    this.roundsplayed +
                    '~',
                  code: code,
                  id: season,
                  title: 'Round ' + this.roundsplayed + ' Draw'
                }
              }"
            >
              <h3 class="drawtext">Draw</h3>
            </b-nav-item>
          </b-col>
          <b-col
            class="LeadCol bline"
            :class="{
              pulse: live_scoring == 'Y',
              FullLeaderboardCol:
                days_away == '0' ||
                days_away == '-4' ||
                days_away == '-3' ||
                days_away == '-2' ||
                days_away == '-1'
            }"
          >
            <b-nav-item
              class="fullNav"
              :to="{
                name: 'reports-page',
                query: {
                  url: this.report,
                  id: season,
                  code: code,
                  title: title
                }
              }"
            >
              <h3
                class="FullLeaderboardNon"
                :class="{
                  FullLeaderboard:
                    days_away == '0' ||
                    days_away == '-4' ||
                    days_away == '-3' ||
                    days_away == '-2' ||
                    days_away == '-1'
                }"
              >
                Full Leaderboard
              </h3>
            </b-nav-item>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
// import axios from "axios";
import PlayerReportCard from "@/ocs/reports/scores/scorecards/components/playerReportCard.vue";
// import Favplayer from "@/components/favplayer.vue";
import Information from "@/components/information.vue";
export default {
  name: "score",
  props: [
    "data",
    "previousData",
    "home",
    "title",
    "report",
    "code",
    "season",
    "takeover",
    "config",
    "team",
    "match",
    "live",
    "RTCDS",
    "bbTeamEvent",
    "roundsplayed",
    "showGender",
    "Stableford",
    "days_away",
    "live_scoring",
    "multi_tourns",
    "web_sponsor"
  ],
  components: {
    PlayerReportCard,
    // Favplayer,
    Information
  },
  data() {
    return {
      compare: [],
      up: "up",
      down: "down",
      scores: [],
      clickedRow: -1,
      clickedRowCompare: -1,
      cardID: [],
      playerCardData: [],
      toggle: "",
    };
  },
  methods: {
    isMobile() {
      if (screen.width <= 768) {
        return true;
      } else {
        return false;
      }
    },
    get_overall: function(i) {
      this.overall = {};
      this.point_list = [];
      this.proj_score = {};
      this.data.overall.overall_entry.forEach(function(value, i) {
        console.log("IN Overall");
        if (value.nat == "EUR") {
          console.log("In EUR");
          this.overall.eu = value;
          console.log("this.overall.eu");
          console.log(this.overall.eu);
          var eu_score = parseFloat(value.points);
          var float_point = eu_score % 1;
          eu_score = Math.trunc(eu_score);
          for (i = 0; i < eu_score; i++) {
            this.point_list.push({
              class: "eu"
            });
          }
          if (float_point > 0) {
            this.point_list.push({
              class: "eu-half"
            });
          }
          var num =
            parseFloat(this.overall.eu.points) + parseFloat(this.prov_score.eu);
          this.proj_score.eu = Math.round(num * 10) / 10;
        } else if (value.nat == "USA") {
          this.overall.usa = value;
          var us_score = parseFloat(value.points);
          var float_point1 = us_score % 1;
          us_score = Math.trunc(us_score);
          if (float_point1 > 0) {
            this.point_list.push({
              class: "us-half"
            });
          }
          for (i = 0; i < us_score; i++) {
            this.point_list.push({
              class: "us"
            });
          }
          var num1 =
            parseFloat(this.overall.usa.points) +
            parseFloat(this.prov_score.us);
          this.proj_score.us = Math.round(num1 * 10) / 10;
        }
      });

      var proj_diff = this.proj_score.eu - this.proj_score.us;
      this.proj_diff = Math.abs(proj_diff);

      console.log("this.proj_score.eu ==== ");
      console.log(this.proj_score.eu);
      var holder = this.proj_score.eu + this.proj_score.us;

      this.proj_list = [];

      console.log(this.proj_score);

      for (i = 0; i < this.proj_score.eu; i++) {
        this.proj_list.push({
          class: "eu"
        });
      }

      for (i = holder; i < 28; i++) {
        this.proj_list.push({
          class: "none"
        });
      }

      for (i = 0; i < this.proj_score.us; i++) {
        this.proj_list.push({
          class: "us"
        });
      }
      console.log(this.proj_list);

      var keep_going = true;
      if (this.point_list.length < 28) {
        var diff = 28 - this.point_list.length;
        this.point_list.forEach(function(value, key) {
          if (value.class != this.point_list[key + 1].class) {
            if (keep_going) {
              for (i = 0; i < diff; i++) {
                this.point_list.splice(key + 1, 0, {
                  class: ""
                });
              }
              keep_going = false;
            }
          }
        });
      }
    },
    range: function(start, end) {
      if (this.roundsPlayed === 1) return this.roundsPlayed;
      else
        return Array(end - start + 1)
          .fill()
          .map((_, idx) => start + idx);
    },
    rowClickedCompare: function(index) {
      if (this.home == true) {
        // console.log("TRUE");
        this.clickedRowCompare = this.reportTitle;
      } else {
        if (
          this.tablePick === "Score" ||
          (this.tablePick === "Live" && this.clickedRowCompare !== index)
        )
          this.clickedRowCompare = index;
        else this.clickedRowCompare = -1;
      }
    },
    rowClicked: function(index) {
      if (this.home == true) {
        // console.log("TRUE");
        this.clickedRow = this.reportTitle;
      } else {
        if (
          this.tablePick === "Score" ||
          (this.tablePick === "Live" && this.clickedRow !== index)
        )
          this.clickedRow = index;
        else this.clickedRow = -1;
      }
    },
    playerCard: function(playerNo) {
      if (this.toggle == true) {
        this.toggle = true;
        // console.log("this.toggle")
        // console.log(this.toggle)
        var playerData = {};
        // var cardID = event.target.getAttribute("title");
        console.log("playerData");
        console.log(playerData);
        var cardID = playerNo;
        console.log("cardID");
        console.log(cardID);
        for (var i of this.data.scores.scores_entry) {
          console.log("in scores");
          if (i.member_no == playerNo) {
            this.playerData = i;
            // console.log("playerData 998");
            // console.log(this.playerData);
            break;
          }
        }
        for (var t of this.data.team_scores.team_scores_entry) {
          // console.log("in scores");
          if (t.member_no == playerNo) {
            this.playerDataTeam = t;
            // console.log("playerData 998");
            // console.log(this.playerDataTeam);
            break;
          }
        }
      } else {
        this.toggle = false;
        // console.log("this.toggle")
        // console.log(this.toggle)
      }
    }
  },
  computed: {
    tablePick: function(picked) {
      if (this.title.includes("Scores") || this.title.includes("Scoreboard"))
        picked = "Score";
      else if (this.title.includes("Live")) picked = "Live";
      return picked;
    },
    roundsPlayed() {
      return parseInt(this.data.rounds_played);
    },
    Eds(reportData) {
      if (this.home === false) {
        if (
          this.title.includes("Scores") ||
          this.title.includes("Scoreboard") ||
          this.title.includes("Live")
        )
          if (Array.isArray(this.data.scores.scores_entry)) {
            console.log("isArray");
            reportData = this.data.scores.scores_entry;
          } else {
            console.log("is not Array");
            reportData = [this.data.scores.scores_entry];
            console.log("reportData");
            console.log(reportData);
          }
        return reportData;
      } else {
        if (
          this.title.includes("Scores") ||
          this.title.includes("Scoreboard") ||
          this.title.includes("Live")
        )
          if (Array.isArray(this.data.scores.scores_entry)) {
            // reportData = this.data.scores.scores_entry.slice(0, 6);
            if (this.multi_tourns == 'Y') {
              reportData = this.data.scores.scores_entry.slice(0, 5);
            } else {
              reportData = this.data.scores.scores_entry.slice(0, 6);
            }
            // console.log('One')
          } else {
            if (this.multi_tourns == 'Y') {
              reportData = [this.data.scores.scores_entry].slice(0, 5);
            } else {
              reportData = [this.data.scores.scores_entry].slice(0, 6);
            }
            // console.log('Two')
          }
        return reportData;
      }
    }
  },
  watch: {
    Eds(newValue, oldValue) {
      newValue.forEach((value1, key1) => {
        oldValue.forEach((value2, key2) => {
          if (value1.id === value2.id) {
            if (key1 < key2) {
              value1.posChang = "up";
            } else if (key1 > key2) {
              value1.posChang = "down";
            }
          }
        });
      });

      // console.log(newValue);
    },
    immediate: true,
    deep: true
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    }
  },
  mounted() {
    this.get_overall();
  }
};
</script>

<style scoped>
::v-deep tr.tr-kids:hover {
    background-color: #f7f7f7 !important;
}
tr.compareBk {
  background-color: #ecb405!important;
}
.strokesColour {
  color: #3c9933 !important;
  font-weight: bold;
  font-style: italic; 
  font-size: 12px;
}
::v-deep .C {
  color: #0f61ce !important;
  font-size: 40px !important;
  line-height: 50px !important;
  vertical-align: bottom;
  font-size: 60px !important;
  line-height: 0px !important;
  vertical-align: middle;
  margin-top: -12px;
  display: inline-block;
}
::v-deep .B {
  color: #333333 !important;
  font-size: 40px !important;
  line-height: 50px !important;
  vertical-align: bottom;
  font-size: 60px !important;
  line-height: 0px !important;
  vertical-align: middle;
  margin-top: -12px;
  display: inline-block;
}
::v-deep .R {
  color: #bb0000 !important;
  font-size: 40px !important;
  line-height: 50px !important;
  vertical-align: bottom;
  font-size: 60px !important;
  line-height: 0px !important;
  vertical-align: middle;
  margin-top: -12px;
  display: inline-block;
}
::v-deep .LeadCol.bline.col.FullLeaderboardCol {
  background: #ff5c38;
}
::v-deep .bline:hover {
  border-left: 1px solid;
  background-color: #01477b;
}
::v-deep .DrawCol.col:hover {
  background-color: #01477b;
  padding: 0;
}
::v-deep.homeLeaderHomeNon:hover {
  background-color: #01477b;
  text-align: center !important;
  color: white;
  /* width: 111% !important; */
  padding: 0 !important;
  font-size: 11pt;
}
img.courseLogo.ss {
  width: 50px;
}
.bline {
  border-left: 1px solid;
}
a.homeLinkPlayer:hover {
  cursor: pointer;
  color: #035699;
  text-decoration: none;
}
::v-deep td.PlayerName:hover {
  color: #035699;
}
::v-deep .iconRight:hover {
  color: #035699;
}
::v-deep .t3:hover {
  background-color: #03477b !important;
  color: white;
}
::v-deep tr.homeTd-size.homeTd-sizehover:hover {
  background-color: #f6f5f5 !important;
}
::v-deep tr:hover {
  background-color: #dfdfdf !important;
}
td.smallsize {
  font-size: 0.7rem;
}
span.smallsize {
  font-size: 0.7rem;
}
::v-deep .home.Matchwidth {
  width: 458px !important;
}
td.RightAligned {
  text-align: right;
}
th.RightAligned {
  text-align: right;
  padding-right: 15px;
}
td.LeftAligned {
  text-align: left !important;
}
th.LeftAligned {
  text-align: left !important;
}
td.wineurope {
  background-color: rgb(37, 37, 149) !important;
  color: #fff;
}
td.winusa {
  background-color: rgb(165, 42, 42) !important;
  color: #fff;
}
.LiveScoring {
  background-color: #000;
  padding: 6px;
  font-size: 16px;
  color: #fff;
  text-align: center;
}
.overallSection {
  padding-left: 15px;
  padding-right: 15px;
}
.Right {
  text-align: right;
  color: #fff;
  padding: 6px;
  font-size: 16px;
}
.Middle {
  text-align: center;
  background-color: #000;
  color: #fff;
  padding: 6px;
  font-size: 16px;
}
.Left {
  text-align: left;
  color: #fff;
  padding: 6px;
  font-size: 16px;
}
.MPRound_Name {
  background-color: #01477b !important;
  color: #ffffff;
  margin-top: 1.5em;
  text-align: center;
  padding: 6px;
  margin-bottom: 20px;
}
th.MPCenter {
  text-align: center;
}
td.MPCenter {
  text-align: center;
}
::v-deep .informationCLass {
  margin-top: 0px !important;
}
::v-deep input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
  display: none;
}
::v-deep td.wrapper input:checked ~ label:before {
  color: #ffc107;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 19px;
  position: relative;
  top: 5px;
  content: "\f005";
  font-family: FontAwesome;
  display: inline-block;
}
::v-deep td.wrapper label:before {
  content: "\f005";
  font-family: FontAwesome;
  display: inline-block;
  color: darkgray;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 19px;
  position: relative;
  top: 5px;
}
/* Home Styles */
/* ::v-deep .custom-checkbox .custom-control-label::before {
  color: #fff;
  border-color: transparent;
  background-color: transparent;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/05/Star_Deselected.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 19px;
  height: 19px;
}
::v-deep .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: transparent;
  background-color: transparent;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/05/Star_Selected.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 19px;
  height: 19px;
} */
::v-deep
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::after {
  display: none;
}
::v-deep .custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
  display: none;
}
.pulse {
  animation: pulse-red 2s infinite;
  border-left: 0px solid !important;
    background-color: #ff5c39!important;
}
.pulse:hover {
  background-color: #ff5c39 !important;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
span.favtextHead {
  font-size: 13pt;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 20px;
  display: block;
  margin-bottom: 20px;
  color: #03477b;
}
tr.favSec > th {
  color: white !important;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #edb503;
  font-weight: 400;
  font-size: 12pt;
  padding-top: 12px;
  padding-bottom: 12px;
  z-index: 1;
  padding-left: 10px;
}
h3.drawtext {
  font-size: 10pt;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 4px;
  text-align: center;
  color: #fff !important;
  padding-top: 10px;
  font-size: 1rem;
}
.DrawCol.col {
  background-color: #015598;
  padding: 0;
}
.LeadCol {
  padding: 0;
  background-color: #015598;
}
td.Playerhome {
  cursor: default !important;
}
td.PlayerName {
  cursor: pointer;
}
td.PlayerName:hover {
  text-decoration: none;
}
tr.compareBk {
  /* background: #03477b!important;
  color: #fff; */
}
tr.compareBk > td {
  /* color: #fff!important; */
}
tr.compareBk > td > a {
  /* color: #fff!important; */
}
::v-deep .home.takeOver {
  width: 300px;
  position: absolute;
  top: 4.55em;
  right: -0.94em;
}
.custom-control {
  position: relative;
  z-index: 0;
  display: block;
  min-height: 1.5rem;
  padding-left: 0.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}
img.favStar {
  width: 19px;
  cursor: pointer;
}
.noPadLeft {
  /* padding-left: 0px; */
}
.hideRepresentsHome {
  display: none;
}
p.homePlayername {
  color: #212529;
}
td.homePlayername {
  color: #212529;
}
a.homePlayername {
  color: #212529;
}
a.homeResult {
  color: #055498 !important;
}
th.representsText {
  text-align: left !important;
}
td.representsText {
  text-align: left !important;
}
tr.homeTd-size {
  background-color: #fff !important;
  color: #055498;
  border-top: 1px solid #dee2e6 !important;
}
tr.drawHome {
  border-top: 0px solid #dee2e6 !important;
}
tr.drawHome > td.drawPos {
  top: 0px;
}
tr.drawHome > td > a {
  color: #055498 !important;
}
tr.homeTd-size > td {
  /* font-size: 11pt; */
  font-size: 13px;
  color: #212529;
  padding-top: 0px;
  padding-bottom: 0px;
}
tr.homeTd-size > td.posnum {
  margin-top: 5px;
  margin-bottom: 4px;
}
tr.homeTd-size.homeTd-sizehover.takeTR > td.posnum {
  margin-top: 8px;
  margin-bottom: 7px;
}
tr.homeTd-size.homeTd-sizehover.takeTR.posMoveHeight > td.posnum {
  margin-top: 9px;
  margin-bottom: 8px;
}
.FullLeaderboardCol:hover {
  background: #ff5c39;
}
h3.FullLeaderboard {
  font-size: 14pt;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 4px;
  text-align: center;
  background: #ff5c39;
  color: #fff !important;
  /* padding-bottom: 10px; */
  padding-top: 10px;
  font-size: 1rem;
}
h3.FullLeaderboardNon {
  font-size: 14pt;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 4px;
  text-align: center;
  /* background: #015598; */
  color: #fff !important;
  /* padding-bottom: 10px; */
  padding-top: 10px;
  font-size: 1rem;
}
.natNone {
  display: none;
}
.fullNav {
  padding-top: 0 !important;
  display: block !important;
}
.fullNav > .nav-link {
  padding: 0 1rem;
  color: white;
}
.table-responsive {
  margin-bottom: 0;
}
.courseLogo {
  width: 84px;
  padding-top: 25px;
  max-width: 70px;
  min-height: 70px;
  max-width: 84px;
}

.homeLeader {
  text-align: center !important;
  background-color: #03477b !important;
  color: white;
  /* width: 111% !important; */
  padding: 0 !important;
  font-size: 11pt;
  padding-bottom: 18px !important;
}
.homeLeaderHome {
  background-color: #ff5c39 !important;
  text-align: center !important;
  color: white;
  /* width: 111% !important; */
  padding: 0 !important;
  font-size: 11pt;
}
.homeLeaderHomeNon {
  /* background-color: #015598; */
  text-align: center !important;
  color: white;
  /* width: 111% !important; */
  padding: 0 !important;
  font-size: 11pt;
}
.female {
  color: #e33;
}
.male {
  color: #037;
}
span.multiDot-size-reportgender {
  font-size: 30px !important;
  line-height: 0px !important;
  position: relative;
  top: 6px;
}
span.multiDot-size-report {
  /* float: right; */
  font-size: 60px !important;
  line-height: 0px !important;
  position: relative;
  top: 3px;
}
.homeHead {
  font-size: 11pt;
  padding: 20px;
  text-align: center;
  font-weight: 400;
  padding-bottom: 0px;
}
.homeHide {
  display: none;
}
tr:nth-child(odd) {
  background-color: white;
}

.home {
  /* width: 320px;
  position: absolute;
  top: 13px;
  right: 15%; */
}
.homeCell {
  padding: 0.75rem;
  font-size: 10pt;
}
.homeCell > a {
  color: #055498;
}
.posCell {
  width: 2%;
}
.scoreCell {
  width: 2%;
}
.sponsorHome {
  margin-top: -2.2px;
  margin-left: 10px;
  width: 37px;
}
/* Main Styles */
td.up.blackTee {
  color: #000;
}
td.down.blackTee {
  color: #000;
}
th.PadLeft {
  padding-left: 10px;
}
img.titliestLogo {
  height: 12px;
  margin-bottom: 1px;
  margin-left: 10px;
}
th.playersName-score {
  padding-left: 10px;
}
td.centerTime {
  text-align: center;
}
th.Timecenter {
  text-align: center;
}
td.drawName > a {
  color: #000;
}
.drawPos {
  max-width: 100% !important;
  text-align: -webkit-center !important;
  margin-top: 0px !important;
  position: relative;
  top: 3px;
}
.title {
  text-align: center;
  background-color: #01477b;
  color: white;
  margin-left: 0;
  margin-right: 0;
}
.title > .col > h3 {
  color: #fff;
  padding: 15px 0 15px 10px;
  font-size: 13pt;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 24px;
  text-transform: capitalize;
  background-color: #01477b;
  text-align: left;
  font-weight: 400;
}
.tLogo {
  width: 65px;
  margin-top: -5px;
  margin-right: 15px;
}
td.catD {
  background: #015699;
  color: #fff;
}
.sponLogo {
  margin-top: 15px;
  float: right;
  padding-right: 27px;
  color: white;
  font-size: 12pt;
}
.title > p {
  font-size: 16px !important;
  margin-bottom: 0;
}
th {
  color: white !important;
  position: sticky;
  top: 0;
  background-color: #42a7c6;
  font-weight: 400;
  font-size: 12pt;
  padding-top: 12px;
  padding-bottom: 12px;
  z-index: 1;
  padding-left: 10px;
}
table {
  width: 100%;
}
thead > tr {
  color: white;
}
td:first-child {
  text-align: center;
}
th:first-child {
  text-align: center;
  padding-left: 2px;
}
th:nth-child(3) {
  text-align: left;
}
td:nth-child(3) {
  text-align: left;
}
.odd {
  background-color: white !important;
  padding-bottom: 0;
  padding-top: 0;
}
.even {
  background-color: #e6f4ff !important;
  padding-bottom: 0;
  padding-top: 0;
}
tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.03);
}
.titlest {
  margin-top: -2.2px;
  margin-left: 10px;
  width: 37px;
}
td {
  font-size: 1rem;
  padding: 0.75rem;
}
td > a {
  /* color: #055498; */
  color: #000;
}
tr > :nth-child(2) {
  /* color: #337ab7; */
}
td.posnum {
  padding: 5px;
  padding-top: 4px;
  padding-bottom: 10px;
  display: block;
  max-width: 58px;
  /* margin-left: auto; */
  margin-right: auto;
  margin-top: 8px;
  margin-bottom: 0px;
}
td.posnum > span {
  padding: 5px;
  display: block;
  max-width: 58px;
}
.noPos > span {
  color: transparent;
  padding: 5px;
  display: block;
  max-width: 58px;
}
.flag {
  width: 30px;
  box-shadow: 0 0 1px #555;
}
.up {
  color: #a52a2a;
  /* color: #1e90ff; */
}
.down {
  color: #1e90ff;
  /* color: #a52a2a; */
}
.Arrowup {
  /* color: #a52a2a; */
  color: #1e90ff;
}
.Arrowdown {
  /* color: #1e90ff; */
  color: #a52a2a;
}
.text-down {
  background: transparent;
  /* text-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: glow-red 2s infinite;
  animation-iteration-count: 6; */
}

@keyframes glow-red {
  to {
    /* text-shadow: 0 0 10px rgba(255, 82, 82, 0.7); */
  }
}

.text-up {
  background: transparent;
  /* text-shadow: 0 0 0 0 rgba(51, 217, 178, 1); */
  /* animation: glow-green 2s infinite; */
  /* animation-iteration-count: 6; */
}

@keyframes glow-green {
  to {
    /* text-shadow: 0 0 10px rgba(51, 217, 178, 0.7); */
  }
}
.cut {
  padding-top: 6px !important;
  padding-right: 6px !important;
  padding-bottom: 6px !important;
  font-size: 10pt !important;
  vertical-align: middle;
  padding-left: 8px !important;
  background: #aa0003;
  color: white;
}

@media only screen and (max-width: 1024px) {
  .home {
    /* width: 350px; */
    width: 100% !important;
    position: inherit !important;
    top: 30px;
    /* top: -80px;  */
    right: 30%;
  }
  .home > div > div {
    max-width: 100%;
  }
}
@media only screen and (min-width: 768px) {
  .Mob {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .mobLevel {
    display: flex;
  }
  img.titliestLogo {
    height: 12px;
    margin-bottom: 1px;
    margin-left: 0px;
    display: block;
    margin-top: 5px;
  }
  .title > .col > h3 {
    color: #fff;
    padding: 15px 0 15px 10px;
    font-size: 11pt;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 24px;
    text-transform: capitalize;
    background-color: #01477b;
    text-align: left;
    font-weight: 400;
  }
  .titleLeftmobileWidth {
    width: 80%;
    max-width: 80%;
  }
  .informationMobilewidth {
    width: 20%;
    max-width: 20%;
  }
  .Desk {
    display: none;
  }
  .home {
    position: inherit;
    width: 100%;
  }
  .homeLeader {
    margin-left: -40px;
  }
  .homewidthTakover {
    margin-left: -15px;
  }
  .hideOnMobile {
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .hideOnMob {
    display: none;
  }
  span.multiDot-size-reportgender {
    font-size: 30px !important;
    line-height: 0px !important;
    position: relative;
    top: 8px;
  }
  .tableMob {
    width: 275%;
  }
  .home {
    position: inherit;
    width: 100%;
  }
  ::v-deep tr > :nth-child(2) {
    font-size: 10pt;
  }
  ::v-deep th {
    font-size: 10pt;
  }
  ::v-deep td {
    font-size: 10pt;
    padding: 0.75rem;
  }
  ::v-deep .dropdown {
    width: 100%;
    padding-right: 12px;
  }
  ::v-deep .HideMovResult {
    display: none;
  }
}
</style>
